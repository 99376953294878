/* eslint-disable no-nested-ternary */
import React from 'react';
import { styled } from '@mui/material/styles';
import Youtube from 'react-lazyload-youtube';
import 'react-lazyload-youtube/dist/index.css';
import { Box } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Image from 'next/image';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import blur from 'public/imgs/1x1.webp';

const PREFIX = 'ActivityGallery';

const classes = {
  tourDayImg: `${PREFIX}-tourDayImg`,
  sliderWrap: `${PREFIX}-sliderWrap`,
  sliderImg: `${PREFIX}-sliderImg`,
  carouselContainer: `${PREFIX}-carouselContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.tourDayImg}`]: {
    height: '100%',
    minWidth: '100%',
    width: 'auto',
  },

  [`&.${classes.sliderWrap}`]: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },

  [`& .${classes.sliderImg}`]: {
    height: '100%',
    minWidth: '100%',
    width: 'auto',
  },

  [`& .${classes.carouselContainer}`]: {
    height: '100%',
    '& ul.react-multi-carousel-track': {
      height: '100%',
    },
    '& .arrow': {
      position: 'absolute',
      top: '40%',
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: '50%',
      backgroundColor: 'rgba(48, 48, 48, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },

      '&.arrowLeft': {
        left: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
          left: theme.spacing(3),
        },
      },

      '&.arrowRight': {
        right: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
          right: theme.spacing(3),
        },
      },

      '& > svg': {
        fontSize: theme.typography.pxToRem(32),
      },
    },
  },
}));

const ArrowLeft = (arrowLeftProps) => (
  <span {...arrowLeftProps} className="arrow arrowLeft">
    <ChevronLeftIcon />
  </span>
);

const ArrowRight = (arrowRightProps) => (
  <>
    <span {...arrowRightProps} className="arrow arrowRight">
      <ChevronRightIcon />
    </span>
  </>
);

const ActivityGallery = (props) => {
  const { imgArr, deviceType } = props;

  const responsive = {
    all: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <StyledBox className={classes.sliderWrap}>
        <Carousel
          swipeable
          draggable={false}
          ssr={false} // means to render carousel on server-side.
          showDots
          keyBoardControl
          containerClass={classes.carouselContainer}
          itemClass={classes.itemClass}
          dotListClass={classes.dotsClass}
          responsive={responsive}
          minimumTouchDrag={20}
          customTransition="transform 200ms ease-in"
          customLeftArrow={<ArrowLeft />}
          deviceType={deviceType}
          autoPlay={false}
          shouldResetAutoplay={false}
          customRightArrow={<ArrowRight />}>
          {imgArr?.map((img) => (
            <Box
              className={classes.sliderImg}
              key={img.youtube_video_id || img.image.medium_url}>
              {img.youtube_video_id ? (
                <Youtube
                  videoId={img.youtube_video_id}
                  height="100%"
                  width="100%"
                />
              ) : (
                <Image
                  className={classes.tourDayImg}
                  src={img.image.medium_url}
                  key="index"
                  alt="Eaglerider Motorcycle Tour"
                  placeholder="blur"
                  blurDataURL={blur.src}
                  layout="fill"
                  objectFit="cover"
                  unoptimized
                />
              )}
            </Box>
          ))}
        </Carousel>
      </StyledBox>
    </>
  );
};

export default ActivityGallery;
