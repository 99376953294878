/* eslint-disable no-nested-ternary */
import React from 'react';
import { styled } from '@mui/material/styles';
import Youtube from 'react-lazyload-youtube';
import 'react-lazyload-youtube/dist/index.css';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import ActivityGallery from 'views/tours-module/TourView/components/ActivityGallery';
import blur from 'public/imgs/1x1.webp';

const PREFIX = 'TourDay';

const classes = {
  tourDayContainer: `${PREFIX}-tourDayContainer`,
  tourDayCard: `${PREFIX}-tourDayCard`,
  tourDayImgBox: `${PREFIX}-tourDayImgBox`,
  tourDayImg: `${PREFIX}-tourDayImg`,
  tourDayTitleHeader: `${PREFIX}-tourDayTitleHeader`,
  tourDayDisc: `${PREFIX}-tourDayDisc`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.tourDayContainer}`]: {
    height: '100%',
    padding: theme.spacing(2, 0.5),
  },

  [`& .${classes.tourDayCard}`]: {
    position: 'relative',
    borderRadius: 2,
    boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.09)',
    overflow: 'hidden',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  [`& .${classes.tourDayImgBox}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: theme.spacing(27.5),
  },

  [`& .${classes.tourDayImg}`]: {
    height: '100%',
    minWidth: '100%',
    width: 'auto',
  },

  [`& .${classes.tourDayTitleHeader}`]: {
    padding: theme.spacing(3, 2, 2),
    '& h6': {
      color: theme.palette.secondary.light,
    },
  },

  [`& .${classes.tourDayDisc}`]: {
    padding: theme.spacing(0, 2),
    margin: theme.spacing(2, 0, 3),
    color: theme.palette.secondary.light,
    height: theme.spacing(25),
    overflowY: 'scroll',
  },
}));

const TourDay = (props) => {
  const { activity, sameDayTour } = props;
  const {
    day,
    title,
    distance,
    assets,
    description,
    start,
    end,
    ssrRender,
    deviceType,
  } = activity;

  const { t } = useTranslation('fe_er_tour_day');

  return (
    <StyledBox className={classes.tourDayContainer}>
      <Box className={classes.tourDayCard}>
        <Grid
          container
          alignItems="flex-start"
          spacing={1}
          className={classes.tourDayTitleHeader}>
          {sameDayTour ? (
            <Grid item xs={12}>
              <Typography500 variant="h4">{title}</Typography500>
            </Grid>
          ) : (
            <Grid item xs={5}>
              <Typography500 variant="h4">
                {t('fe_er_tour_day:activity_day', {
                  day,
                })}
              </Typography500>
            </Grid>
          )}
          {!sameDayTour && (
            <Grid item xs={7} align="right">
              <Typography variant="h6">
                {t('fe_er_tour_day:quantity_in_miles', {
                  quantity: distance.miles,
                })}
                /
                {t('fe_er_tour_day:quantity_in_km', {
                  quantity: distance.km,
                })}
              </Typography>
            </Grid>
          )}
          {!sameDayTour && (
            <Grid item xs={12}>
              <Typography variant="h6">
                {title}
                {start ? ` - ${t('fe_er_tour_day:arrival')}` : ''}
                {end ? ` - ${t('fe_er_tour_day:departure')}` : ''}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Box>
          <Box className={classes.tourDayImgBox}>
            {assets.length > 1 && !ssrRender ? (
              <>
                <ActivityGallery imgArr={assets} deviceType={deviceType} />
              </>
            ) : assets.length == 1 || ssrRender ? (
              assets[0].image?.medium_url ? (
                <Image
                  className={classes.tourDayImg}
                  src={assets[0].image.medium_url}
                  alt={title}
                  placeholder="blur"
                  blurDataURL={blur.src}
                  layout="fill"
                  objectFit="cover"
                  unoptimized
                />
              ) : (
                assets[0].youtube_video_id && (
                  <Youtube
                    videoId={assets[0].youtube_video_id}
                    height="100%"
                    width="100%"
                  />
                )
              )
            ) : (
              <Image
                className={classes.tourDayImg}
                src="/imgs/default/er_default.png"
                alt={title}
                placeholder="blur"
                blurDataURL={blur.src}
                layout="fill"
                objectFit="cover"
                unoptimized
              />
            )}
          </Box>

          <Box className={classes.tourDayDisc}>
            <Typography variant="body2">{description}</Typography>
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default TourDay;
