import React from 'react';
import { Button, Box } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';

import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';

import ReviewsTitle from 'views/common/components/UI/ReviewsTitle';

const ReviewCard = dynamic(
  () => import('views/common/components/UI/ReviewCard'),
  { ssr: false },
);

const Reviews = (props) => {
  const {
    totalCount,
    totalReviews,
    tourTitle,
    onSubmitReview,
    reviewError,
    hasReview,
    addReviewState,
    onSubmitVote,
    editReviewState,
    reviewFormData,
    onDataChange,
  } = props;
  const { t } = useTranslation('fe_er_motorcycle_tours');

  const appNavigator = useAppNavigator();

  let currentUserReview;
  let reviews;

  if (hasReview) {
    [currentUserReview, ...reviews] = totalReviews;
  } else {
    reviews = totalReviews;
  }

  return (
    <>
      <ReviewsTitle
        title={t('fe_er_motorcycle_tours:reviews')}
        reviewsCount={totalCount}
        reviewOverlayData={
          !hasReview && {
            data: reviewFormData,
            ratingLable: tourTitle,
            onDataChange,
            submitReviewBtn: {
              label: t('fe_er_motorcycle_tours:rate_tour'),
              action: onSubmitReview,
            },
            reviewError,
            state: addReviewState,
          }
        }
      />

      <Box>
        {currentUserReview && (
          <ReviewCard
            key={currentUserReview.id}
            reviewId={currentUserReview.id}
            userImage={currentUserReview.reviewer.avatar.small_url}
            userName={currentUserReview.reviewer.name}
            reviewDate={dayjs(currentUserReview.created_at).format(
              'MMMM D, YYYY HH:mm a',
            )}
            rate={currentUserReview.star_rating}
            reviewDetails={[
              {
                title: currentUserReview.title,
                description: currentUserReview.body,
              },
            ]}
            editReviewDetails={{
              data: reviewFormData,
              onDataChange,
              reviewError,
              ratingLable: tourTitle,
              submitReviewBtn: {
                label: t('fe_er_motorcycle_tours:rate_tour'),
                action: onSubmitReview,
              },
              state: editReviewState,
            }}
            reviewVotes={{
              count: currentUserReview.votes,
              currentUserVote: currentUserReview.current_user_vote,
            }}
            onSubmitVote={onSubmitVote}
          />
        )}
        {reviews?.map((review) => (
          <ReviewCard
            key={review.id}
            reviewId={review.id}
            userImage={review.reviewer.avatar.small_url}
            userName={review.reviewer.name}
            reviewDate={dayjs(review.created_at).format('MMMM D, YYYY HH:mm a')}
            rate={review.star_rating}
            reviewDetails={[
              {
                title: review.title,
                description: review.body,
              },
            ]}
            reviewVotes={{
              count: review.votes,
              currentUserVote: review.current_user_vote,
            }}
            onSubmitVote={onSubmitVote}
          />
        ))}
      </Box>

      {totalCount > 2 && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              appNavigator.push(PATHS.tour.reviews(appNavigator.asPath));
            }}>
            {`${t('fe_er_motorcycle_tours:view_all_reviews', {
              count: totalCount,
            })}`}
          </Button>
        </Box>
      )}
    </>
  );
};
export default Reviews;
