/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Head from 'next/head';

import {
  Container,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  Alert,
  AlertTitle,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ErrorIcon from '@mui/icons-material/Error';

import Dinero from 'lib/utils/Dinero';
import moneyFormat from 'lib/utils/moneyFormat';
import ScrollTo from 'lib/utils/ScrollTo';
import PATHS from 'lib/navigation/paths';
import { setGoogleAnalytics } from 'redux/features/globalData-slice';
import { GA_EVENT_CATEGORY, TOUR_TYPES } from 'lib/utils/CONSTANTS';
import generateMetaData from 'lib/utils/generateMetaData';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { setLoading, setSnackbar } from 'redux/features/ui-slice';
import axios, { API } from 'lib/config/axios';

import TextBox from 'views/common/components/UI/TextBox';
import {
  ActionBar,
  LazyLoad,
  MotorcycleAdventuresCaughtOnCamera,
  // MotorcycleAdventuresCaughtOnCameraSkelton
} from 'views/common/components';
import StickyTabsBar from 'views/common/components/UI/StickyTabsBar';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';

import TourMainTitleWrapper from 'views/tours-module/TourView/components/TourMainTitleWrapper';
import RelatedToursWrapper from 'views/tours-module/TourView/components/RelatedToursWrapper';
import TourDescription from 'views/tours-module/TourView/components/TourDescription';
import TourInclusions from 'views/tours-module/TourView/components/TourInclusions';
import GalleryContainer from 'views/tours-module/TourView/components/GalleryContainer';
import TourItineraryWrapper from 'views/tours-module/TourView/components/TourItineraryWrapper';
import ReviewsWrapper from 'views/tours-module/TourView/components/ReviewsWrapper';
import ReservationWrapper from 'views/tours-module/TourView/components/ReservationWrapper';
import ExperienceTouts from 'views/tours-module/TourView/components/ExperienceTouts';
import { getYouTubeID } from 'lib/utils/getYouTubeID';
import { PAGES_GA_IDENTIFIERS } from 'views/common/utils/constants';

const PREFIX = 'TourView';

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  contentContainer: `${PREFIX}-contentContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Box)(({ theme }) => ({
  [`& .${classes.mainContainer}`]: {
    // maxWidth: '1330px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  [`& .${classes.contentContainer}`]: {
    paddingBottom: theme.spacing(3.75),
    marginBottom: theme.spacing(8.75),
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  '& .departureAlert': {
    '& .MuiAlert-icon': {
      color: theme.palette.info.main,
      '& svg': {
        fontSize: 22,
      },
    },
  },
  // To fix conflict issue at mobile when swap at camera slider  and effect on tabs scroll
  '& .TVWhatInclude': {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4.75),
    },
  },
  '& .TVCameraContainer': {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
    },
  },
}));

const StyledActionBar = styled(ActionBar)(({ theme }) => ({
  '& .TVStartingPrice': {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));
const TourView = (props) => {
  const {
    serverData,
    ssrRender,
    deviceType,
    slugData,
    bookingDetailsDataInit,
  } = props;

  const { t } = useTranslation('fe_er_motorcycle_tours');
  const dispatch = useDispatch();
  const googleAnalytics = useSelector(
    (state) => state.globalData.googleAnalytics,
  );
  const handleErrorResponse = useErrorsHandler();
  const appNavigator = useAppNavigator();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [pageData, setPageData] = useState(serverData);
  const [bookingDetails, setBookingDetails] = useState(bookingDetailsDataInit);

  const getInitialIncludeBikeState = () => {
    if (pageData.tour.acceptable_reservations_type === 'any_reservations') {
      if (bookingDetails && bookingDetails.selected_package_id) {
        const idx = bookingDetails.packages.findIndex(
          (pack) => pack.id === bookingDetails.selected_package_id,
        );
        if (bookingDetails.packages[idx].motorcycles_count) {
          return true;
        }
        return false;
      }
      return true;
    }

    if (
      pageData.tour.acceptable_reservations_type
      === 'with_motorcycle_reservations_only'
    ) {
      return true;
    }
    return false;
  };

  const [includeBike, setIncludeBike] = useState(getInitialIncludeBikeState());
  const [showActionBar, setShowActionBar] = useState(true);

  const handleShowActionBar = (value) => {
    setShowActionBar(value);
  };

  const convertBookingParams = (params) => Object.entries(params).reduce((obj, [key, val]) => {
    const newKey = key.slice(12, key.length - 1);
    return {
      ...obj,
      [newKey]: val,
    };
  }, {});

  const getBookingDetails = (tourId, params, firstReq, reservationType) => {
    dispatch(setLoading(true));
    let withMotorcycle = params.with_motorcycle;
    if (firstReq) {
      if (reservationType === 'without_motorcycle_reservations_only') {
        withMotorcycle = false;
      } else withMotorcycle = true;
    }

    axios
      .get(API.tours.bookingDetails.replace('{tour_id}', tourId), {
        params: {
          with_motorcycle: withMotorcycle,
          occurrence_id: params.occurrence_id && +params.occurrence_id,
          package_id: params.package_id && +params.package_id,
          packages_count: params.packages_count && +params.packages_count,
          pickup_date: params.pickup_date,
        },
      })
      .then((res) => {
        setBookingDetails(res.data);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        if (error.response.status == 403) {
          if (bookingDetails) {
            setBookingDetails((prev) => ({
              ...prev,
              pickup_date: params.pickup_date,
              packages: [],
            }));

            dispatch(
              setSnackbar({
                open: true,
                severity: 'error',
                message: t('fe_er_motorcycle_tours:no_available_packages'),
              }),
            );
          } else if (includeBike === undefined) setIncludeBike(true);
        } else {
          handleErrorResponse(error, false);
        }
      });
  };

  const getTourData = () => {
    const {
      guided_motorcycle_tours_segment,
      self_drive_motorcycle_tours_segment,
      off_road_tours_segment,
      ...bookingParams
    } = {
      ...appNavigator.query,
    };
    axios
      .get(API.tours.withId.replace('{id}', 'dynamic-id'), {
        params: {
          tour_type_slug: decodeURIComponent(
            guided_motorcycle_tours_segment
              || self_drive_motorcycle_tours_segment
              || off_road_tours_segment,
          ),
          tour_slug: decodeURIComponent(slugData.slugs[0].slug),
        },
      })
      .then((res) => {
        const result = res.data;
        setPageData(result);
        const convertedParams = convertBookingParams(bookingParams);
        getBookingDetails(
          result.tour.id,
          convertedParams,
          true,
          result.tour.acceptable_reservations_type,
        );
      })
      .catch((error) => {
        const result = error.response.data;
        if (error.response.status == 403 && result.error_code === 'ER0001') {
          appNavigator.push(PATHS.motorcycleTours.index());
        } else if (
          error.response.status == 403
          && result.error_code === 'ER0002'
        ) {
          appNavigator.push(
            PATHS.tour.index(
              result.correct_slugs.tour_type_slug,
              result.correct_slugs.tour_slug,
            ),
          );
        } else {
          handleErrorResponse(error, false);
        }
      });
  };

  const createMenuTabsArr = () => {
    const menuTabsArr = [];
    pageData.tour
      && menuTabsArr.push({
        label: t('fe_er_motorcycle_tours:overview'),
        id: 'overview',
      });
    pageData.activities.length > 1
      && menuTabsArr.push({
        label: t('fe_er_motorcycle_tours:itinerary'),
        id: 'itinerary',
      });
    (pageData.inclusions.included_items.length > 0
      || pageData.experience_tout !== null)
      && menuTabsArr.push({
        label: t('fe_er_motorcycle_tours:what_included'),
        id: 'what_included',
      });
    pageData.videos.length > 0
      && menuTabsArr.push({
        label: t('fe_er_motorcycle_tours:tour_experiences'),
        id: 'tour_experiences',
      });
    pageData.related_tours.length !== 0
      && menuTabsArr.push({
        label: t('fe_er_motorcycle_tours:you_might_like'),
        id: 'you_might_like',
      });
    menuTabsArr.push({
      label: t('fe_er_motorcycle_tours:reviews'),
      id: 'reviews',
    });
    return menuTabsArr;
  };

  const getMinStartingPrice = () => {
    if (pageData.tour.starting_price.with_motorcycle) {
      let minPrice = { ...pageData.tour.starting_price.with_motorcycle };

      if (pageData.tour.starting_price.without_motorcycle?.amount) {
        if (
          pageData.tour.starting_price.without_motorcycle.amount
          < minPrice.amount
        ) {
          minPrice = { ...pageData.tour.starting_price.without_motorcycle };
        }
      }

      return moneyFormat(
        Dinero({
          amount: minPrice.amount,
          currency: minPrice.currency_code,
          precision: minPrice.precision,
        }),
      );
    }
    if (pageData.tour.starting_price.without_motorcycle) {
      return moneyFormat(
        Dinero({
          amount: pageData.tour.starting_price.without_motorcycle.amount,
          currency:
            pageData.tour.starting_price.without_motorcycle.currency_code,
          precision: pageData.tour.starting_price.without_motorcycle.precision,
        }),
      );
    }
    return null;
  };

  useEffect(() => {
    dispatch(
      setGoogleAnalytics({
        ...googleAnalytics,
        newsLetterEventCategory: GA_EVENT_CATEGORY.tour,
      }),
    );
    window.gtag('event', 'landing_page_view', {
      page_identifier: PAGES_GA_IDENTIFIERS.TOUR,
    });
  }, []);

  useEffect(() => {
    if (bookingDetails && typeof includeBike === 'undefined') {
      setIncludeBike(getInitialIncludeBikeState());
    }
  }, [bookingDetails]);
  const prepareMACOComponentData = (videos) => videos.map((video) => ({
    id: video.youtube_video_id,
    videoId: getYouTubeID(video.url.url),
    title: video.title,
    subTitle: video?.subtitle,
    bgImg: video.image.large_url,
    description: video.description,
    path: video.url.url,
    pathLable: video.url.text,
  }));

  const renderSaveNote = useMemo(() => {
    if (
      pageData.inclusions.total_savings
      && pageData.inclusions.included_items.length > 0
    ) {
      const amount = includeBike
        ? pageData.inclusions.total_savings.with_motorcycle.amount
        : pageData.inclusions.total_savings.without_motorcycle.amount;
      return amount > 0;
    }
    return false;
  }, [pageData]);

  return (
    <>
      <Head>
        {pageData?.seo_values && generateMetaData(pageData.seo_values)}
      </Head>
      <Root mb={4} mt={{ md: 0, lg: 3 }}>
        {pageData && (
          <>
            <Box mb={5} className="showTABLET">
              <GalleryContainer
                gallery={pageData.tour.gallery}
                ssrRender={ssrRender}
                deviceType={deviceType}
              />
            </Box>

            <Container maxWidth="xl" className={classes.mainContainer}>
              <Box mb={4}>
                <Grid container spacing={{ md: 0, lg: 6 }}>
                  <Grid item xs={12} lg={8}>
                    <TourMainTitleWrapper
                      tour={pageData.tour}
                      showNumOfDaysAndNights={
                        pageData.tour?.tour_type?.identifier
                        !== TOUR_TYPES.GUIDED_OFFROAD
                      }
                    />

                    <Box mt={2} mb={3} className="showDESKTOP">
                      <GalleryContainer
                        gallery={pageData.tour.gallery}
                        ssrRender={ssrRender}
                        deviceType={deviceType}
                      />
                    </Box>
                    <Box mt={2} id="overview">
                      <TourDescription tour={pageData.tour} />
                    </Box>

                    {renderSaveNote && (
                      <Box my={2} className="showTABLET">
                        <TextBox
                          icon={<NotificationsActiveIcon />}
                          iconColor={theme.palette.info.main}
                          desc={t('fe_er_motorcycle_tours:save_note', {
                            total_saving: moneyFormat(
                              Dinero({
                                amount: includeBike
                                  ? pageData.inclusions.total_savings
                                    .with_motorcycle.amount
                                  : pageData.inclusions.total_savings
                                    .without_motorcycle.amount,
                                currency: includeBike
                                  ? pageData.inclusions.total_savings
                                    .with_motorcycle.currency_code
                                  : pageData.inclusions.total_savings
                                    .without_motorcycle.currency_code,
                                precision: includeBike
                                  ? pageData.inclusions.total_savings
                                    .with_motorcycle.precision
                                  : pageData.inclusions.total_savings
                                    .without_motorcycle.precision,
                              }),
                            ),
                          })}
                          btnText={t('fe_er_motorcycle_tours:show_more')}
                          btnOnClick={() => {
                            ScrollTo('what_included', -70);
                          }}
                        />
                      </Box>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={4}
                    className="showDESKTOP"
                    id={isMobile ? undefined : 'reservation_wrapper'}>
                    <ReservationWrapper
                      bookingDetails={bookingDetails}
                      tourId={pageData.tour.id}
                      startingPrice={pageData.tour.starting_price}
                      includeBike={includeBike}
                      onIncludeBikeChange={setIncludeBike}
                      getBookingDetails={getBookingDetails}
                      acceptableReservationsType={
                        pageData.tour.acceptable_reservations_type
                      }
                      handleShowActionBar={handleShowActionBar}
                      tourType={pageData.tour.tour_type}
                    />
                    {renderSaveNote && (
                      <Box my={2}>
                        <TextBox
                          icon={<NotificationsActiveIcon />}
                          iconColor={theme.palette.info.main}
                          desc={t('fe_er_motorcycle_tours:save_note', {
                            total_saving: moneyFormat(
                              Dinero({
                                amount: includeBike
                                  ? pageData.inclusions.total_savings
                                    .with_motorcycle.amount
                                  : pageData.inclusions.total_savings
                                    .without_motorcycle.amount,
                                currency: includeBike
                                  ? pageData.inclusions.total_savings
                                    .with_motorcycle.currency_code
                                  : pageData.inclusions.total_savings
                                    .without_motorcycle.currency_code,
                                precision: includeBike
                                  ? pageData.inclusions.total_savings
                                    .with_motorcycle.precision
                                  : pageData.inclusions.total_savings
                                    .without_motorcycle.precision,
                              }),
                            ),
                          })}
                          btnText={t('fe_er_motorcycle_tours:show_more')}
                          btnOnClick={() => {
                            ScrollTo('what_included', -70);
                          }}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <Container maxWidth="xl" className={classes.mainContainer}>
              <StickyTabsBar
                stickyBarTabs={createMenuTabsArr()}
                hasRightSideChildren>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    ScrollTo('reservation_wrapper', -50);
                  }}>
                  {t('fe_er_motorcycle_tours:book_tour')}
                </Button>
              </StickyTabsBar>
            </Container>
            <Container maxWidth="xl" className={classes.mainContainer}>
              {pageData.activities.length > 1 && (
                <Box className={classes.contentContainer} mt={6} id="itinerary">
                  <TourItineraryWrapper
                    activitiesArr={pageData.activities}
                    tourName={pageData.tour.name}
                    deviceType={deviceType}
                    ssrRender={ssrRender}
                    sameDayTour={pageData.tour.days_count === 1}
                  />
                </Box>
              )}

              <Box
                className={`${classes.contentContainer} showTABLET`}
                id={!isMobile ? undefined : 'reservation_wrapper'}>
                <ReservationWrapper
                  bookingDetails={bookingDetails}
                  tourId={pageData.tour.id}
                  startingPrice={pageData.tour.starting_price}
                  includeBike={includeBike}
                  onIncludeBikeChange={setIncludeBike}
                  getBookingDetails={getBookingDetails}
                  acceptableReservationsType={
                    pageData.tour.acceptable_reservations_type
                  }
                  tourType={pageData.tour.tour_type}
                  handleShowActionBar={handleShowActionBar}
                />
              </Box>

              {(pageData.inclusions.included_items.length > 0
                || pageData.experience_tout !== null) && (
                <Box
                  className={`${classes.contentContainer} TVWhatInclude `}
                  id="what_included">
                  <Grid container spacing={4}>
                    {pageData.inclusions.included_items.length > 0 && (
                      <Grid item xs={12} lg={8}>
                        <LazyLoad skeleton={<></>} dataLoaded>
                          <TourInclusions
                            tourDetails={pageData.tour}
                            inclusions={pageData.inclusions}
                            includeBike={includeBike}
                          />
                        </LazyLoad>
                      </Grid>
                    )}
                    <Grid item xs={12} lg={4}>
                      <Box display="flex" flexDirection="column-reverse">
                        <LazyLoad skeleton={<></>} dataLoaded>
                          <ExperienceTouts
                            experienceTouts={pageData.experience_tout}
                          />
                        </LazyLoad>

                        {pageData.tour.departure_guaranteed && (
                          <Box mt={{ md: 0, lg: 1.5 }} mb={1.5}>
                            <Alert
                              severity="info"
                              className="departureAlert"
                              icon={<ErrorIcon />}>
                              <AlertTitle>
                                {`100% ${t(
                                  'fe_er_motorcycle_tours:guaranteed_departures_title',
                                )}`}
                              </AlertTitle>
                              {t(
                                'fe_er_motorcycle_tours:guaranteed_departures_text',
                              )}
                            </Alert>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {pageData?.videos.length > 0 && (
                <Box
                  className={`${classes.contentContainer} TVCameraContainer `}
                  id="tour_experiences">
                  <LazyLoad skeleton={<></>} dataLoaded>
                    <MotorcycleAdventuresCaughtOnCamera
                      AdventuresOnCameraItems={prepareMACOComponentData(
                        pageData.videos,
                      )}
                      sectionTitle={t(
                        'fe_er_motorcycle_tours:tour_experiences_caught_on_camera',
                      )}
                      removeContainer
                    />
                  </LazyLoad>
                </Box>
              )}
              {pageData.related_tours.length > 0 && (
                <Box className={classes.contentContainer} id="you_might_like">
                  <LazyLoad skeleton={<></>} dataLoaded>
                    <RelatedToursWrapper
                      tours={pageData.related_tours}
                      deviceType={deviceType}
                      ssrRender={ssrRender}
                    />
                  </LazyLoad>
                </Box>
              )}

              <Box id="reviews">
                <LazyLoad skeleton={<></>} dataLoaded>
                  <ReviewsWrapper
                    tourId={pageData.tour.id}
                    tourTitle={pageData.tour.name}
                    reviewsData={pageData.users_reviews}
                    getTourData={getTourData}
                  />
                </LazyLoad>
              </Box>
            </Container>
          </>
        )}
      </Root>
      {isMobile && pageData && showActionBar && (
        <StyledActionBar>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            {(pageData.tour.starting_price.with_motorcycle
              || pageData.tour.starting_price.without_motorcycle) && (
              <div>
                <Typography500 component="span" variant="h6" pr={0.5}>
                  {`${t('fe_er_motorcycle_tours:starting_at')}`}
                </Typography500>
                <Typography500
                  component="span"
                  variant="h6"
                  className="TVStartingPrice">
                  {`${getMinStartingPrice()}${
                    pageData.tour?.tour_type?.identifier
                    !== TOUR_TYPES.GUIDED_OFFROAD
                      ? t('fe_er_motorcycle_tours:per_person')
                      : ''
                  }`}
                </Typography500>
              </div>
            )}

            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                ScrollTo('reservation_wrapper', -50);
              }}>
              {t('fe_er_motorcycle_tours:select_tour_package')}
            </Button>
          </Box>
        </StyledActionBar>
      )}
    </>
  );
};

export default TourView;
