import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Alert,
} from '@mui/material';
// import DatePicker from '@mui/lab/DatePicker';
import DatePicker from 'views/common/components/UI/DatePicker';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';

import Dinero from 'lib/utils/Dinero';
import moneyFormat from 'lib/utils/moneyFormat';
import { TOUR_TYPES } from 'lib/utils/CONSTANTS';

import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import RadioButtonCardOld from 'views/common/components/UI/RadioButtonCardOld';
import {
  BestPriceGuarantee,
  Counter,
  FormErrors,
} from 'views/common/components';

import PackageRadioButton from 'views/tours-module/TourView/components/PackageRadioButton';

const PREFIX = 'TourPackageSelector';
const classes = {
  tourWidget: `${PREFIX}-tourWidget`,
  tooltip: `${PREFIX}-tooltip`,
  bringOwnContainer: `${PREFIX}-bringOwnContainer`,
  newLabel: `${PREFIX}-newLabel`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.tourWidget}`]: {
    padding: theme.spacing(2),
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: 15,
  },

  [`& .${classes.tooltip}`]: {
    fontSize: theme.typography.pxToRem(13),
  },

  [`& .${classes.bringOwnContainer}`]: {
    position: 'relative',
  },

  [`& .${classes.newLabel}`]: {
    backgroundColor: theme.palette.success.main,
    position: 'absolute',
    top: '18px',
    right: '2px',
    color: theme.palette.common.white,
    padding: theme.spacing(0.25, 1),
    transform: 'translateY(-100%)',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '700',
    borderRadius: 22,
    lineHeight: theme.typography.pxToRem(18),
  },

  '& .guidedAlert': {
    '& .MuiAlert-icon': {
      color: theme.palette.info.main,
      '& svg': {
        fontSize: 22,
      },
    },
  },
  '& .TPSTimeSlotsWrapper': {
    gap: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: theme.spacing(10.75),
    '& .TPSTimeSlots': {
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.grey[100]}`,
      background: theme.palette.grey.A000,
      padding: theme.spacing(1, 2),
      cursor: 'pointer',
      '& .TPSTimeSlotsText': {
        letterSpacing: 0.4,
        fontSize: theme.typography.pxToRem(12),
      },
      '&.active': {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.A000,
      },
    },
  },
  '& .TPSTimeSlotsError': {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    '& .MuiAlert-icon': {
      padding: 0,
      marginRight: theme.spacing(1),
    },
    '& .MuiAlert-message': {
      padding: 0,
    },
  },
  '& .TPSTimeSlotsErrorIcon': {
    fontSize: theme.spacing(2.5),
  },
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '&.customDatePicker': {
    '& .MuiPickersDay-root': {
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiTypography-caption': {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
    },
  },
}));

const CustomDatePicker = React.forwardRef((props, ref) => {
  const {
    calendarOptions, handleDateChange, disableDates, pickupDate, label,
  } = props;

  return (
    <StyledDatePicker
      forwardedRef={ref}
      required
      fullWidth
      color="secondary"
      format="MM/DD/YYYY"
      minDate={dayjs(calendarOptions.min_date)}
      maxDate={dayjs(calendarOptions.max_date)}
      id="tour_start_date"
      label={label}
      placeholder="mm/dd/yyyy"
      value={dayjs(pickupDate || calendarOptions.min_date)}
      onChange={(date) => {
        handleDateChange(date);
      }}
      slotProps={{
        popper: {
          'data-testid': 'tour_start_date',
        },
      }}
      shouldDisableDate={disableDates}
      className="customDatePicker"
      disableCloseOnSelect={false}
    />
  );
});

const OccurrenceMenu = React.forwardRef((props, ref) => {
  const {
    occurrences,
    handleOccurenceChange,
    occurrenceId,
    label,
    getOccurrenceName,
  } = props;
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
      <Select
        ref={ref}
        value={occurrenceId || occurrences[0].id}
        id="tour_date"
        name="tour_date"
        variant="outlined"
        onChange={(e) => handleOccurenceChange(e)}
        fullWidth
        label={label}>
        {occurrences.map((occurrence) => (
          <MenuItem value={occurrence.id} key={occurrence.id}>
            {getOccurrenceName(occurrence)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

const TourPackageSelector = (props) => {
  const {
    bookingDetails,
    includeBike,
    onIncludeBikeChange,
    onBookTour,
    bookingError,
    startingPrice,
    disableDates,
    getBookingDetails,
    acceptableReservationsType,
    handleShowActionBar = () => {},
    tourType,
    handleErrorReset,
  } = props;

  const { t } = useTranslation('fe_er_tour_package_selector');
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const packageSelectorRef = useRef();

  const [widgetData, setWidgetData] = useState(
    bookingDetails
      ? {
        package_id: bookingDetails.selected_package_id,
        occurrence_id: bookingDetails.occurrence_id,
        packages_count: 1,
        pickup_date: bookingDetails.pickup_date,
      }
      : {},
  );

  const clearData = () => {
    setWidgetData((prev) => ({
      ...prev,
      package_id: null,
    }));
  };

  const handleWidgetDataChange = (obj) => {
    setWidgetData((prev) => ({
      ...prev,
      ...obj,
    }));
    // if (Object.keys(obj).includes('package_id'))
    handleErrorReset?.();
  };

  const handleDateChange = (date) => {
    const formatedDate = dayjs(date).format('YYYY-MM-DD');
    handleWidgetDataChange({ pickup_date: formatedDate });
    getBookingDetails({
      ...widgetData,
      pickup_date: formatedDate,
      with_motorcycle: includeBike,
    });
  };

  const handlePackageChange = (id) => {
    handleWidgetDataChange({ package_id: id });
  };

  const handleOccurenceChange = (e) => {
    const occurence = bookingDetails.occurrences.find(
      (occ) => occ.id === e.target.value,
    );

    handleWidgetDataChange({
      occurrence_id: occurence.id,
      pickup_date: occurence.start_date,
    });

    getBookingDetails({
      ...widgetData,
      occurrence_id: occurence.id,
      pickup_date: occurence.start_date,
      with_motorcycle: includeBike,
    });
  };

  const handleDecrement = () => {
    handleWidgetDataChange({ packages_count: widgetData.packages_count - 1 });
  };

  const handleIncrement = () => {
    handleWidgetDataChange({ packages_count: widgetData.packages_count + 1 });
  };

  const handleBookTour = () => {
    onBookTour(widgetData);
  };

  const handleSelectTimeSlots = (item) => {
    handleWidgetDataChange({ availability_id: item.id });
  };

  const getOccurrenceName = (occurrence) => {
    const parts = [dayjs(occurrence.start_date).format('MMM D, YYYY')];

    if (occurrence.soldout) {
      if (occurrence.notes) {
        parts.push(`(${occurrence.notes})`);
      }
      parts.push(`(${t('fe_er_tour_package_selector:sold_out')})`);
    } else if (
      occurrence.maximum_vehicles_allowed
      && occurrence.maximum_vehicles_allowed <= 5
    ) {
      if (occurrence.notes) {
        parts.push(`(${occurrence.notes})`);
      }
      parts.push(
        `(${t('fe_er_tour_package_selector:only')} ${
          occurrence.maximum_vehicles_allowed
        } ${t('fe_er_tour_package_selector:left')})`,
      );
    } else {
      if (occurrence.discount_percent) {
        parts.push('-');
        parts.push(
          `${occurrence.discount_percent}% ${t(
            'fe_er_tour_package_selector:occurrence:off',
          )}`,
        );
      }

      if (occurrence.notes) {
        parts.push(`(${occurrence.notes})`);
      }
    }

    return parts.join(' ');
  };

  const handleIncludeBikeChange = (value) => {
    if (acceptableReservationsType === 'any_reservations') {
      onIncludeBikeChange(value);
      getBookingDetails({ with_motorcycle: value, ...widgetData });
      clearData();
    }
  };

  const scrollCallBack = (entries) => {
    if (entries[0].isIntersecting) {
      handleShowActionBar(false);
    } else {
      handleShowActionBar(true);
    }
  };
  useEffect(() => {
    const scroll = new IntersectionObserver(scrollCallBack, {
      threshold: 0.7,
    });
    if (packageSelectorRef.current) {
      scroll.observe(packageSelectorRef.current);
    }
    return () => {
      if (packageSelectorRef.current) {
        window.scroll.unobserve(packageSelectorRef.current);
      }
    };
  }, [packageSelectorRef]);

  return (
    <Root ref={packageSelectorRef}>
      <Box className={classes.tourWidget} id="tourWidget">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={7}>
            <Typography500 variant="h4">
              {t('fe_er_tour_package_selector:book_your_tour')}
            </Typography500>
          </Grid>

          <Grid item xs={12} md={5} align={!smallScreen ? 'right' : ''}>
            {startingPrice && (
              <Typography variant="h6">
                {`${t('fe_er_tour_package_selector:starting_at')} `}
                <Typography500 variant="h5" component="span">
                  {moneyFormat(
                    Dinero({
                      amount: startingPrice.amount,
                      currency: startingPrice.currency_code,
                      precision: startingPrice.precision,
                    }),
                  )}
                </Typography500>
              </Typography>
            )}
          </Grid>
        </Grid>

        {bookingDetails
          && bookingDetails.tour.type_identifier === 'guided_non_recurring' && (
            <Box my={2}>
              <Alert
                severity="info"
                className="guidedAlert"
                icon={<ErrorIcon />}>
                {t('fe_er_tour_package_selector:guided_note')}
              </Alert>
            </Box>
        )}

        <Box my={2}>
          {bookingDetails
          && (bookingDetails.tour.type_identifier === 'self_drive'
            || bookingDetails.tour.type_identifier === 'guided_recurring') ? (
              <>
                {bookingDetails?.calendar_options && (
                <Tooltip
                  arrow
                  title={t('fe_er_tour_package_selector:minimum_deposit', {
                    deposit_percent:
                      bookingDetails.group_promotion.deposit_percent,
                  })}
                  classes={{ tooltip: classes.tooltip }}>
                  <CustomDatePicker
                    calendarOptions={bookingDetails.calendar_options}
                    handleDateChange={handleDateChange}
                    disableDates={disableDates}
                    pickupDate={widgetData.pickup_date}
                    label={t('fe_er_tour_package_selector:tour_start_date')}
                  />
                </Tooltip>
                )}
                {bookingError?.errors && (
                <FormErrors errorMsgs={bookingError} errorField="pickup_date" />
                )}
              </>
            ) : (
              <>
                {bookingDetails?.occurrences
                && bookingDetails.occurrences.length > 0 && (
                  <Tooltip
                    arrow
                    title={t('fe_er_tour_package_selector:minimum_deposit', {
                      deposit_percent:
                        bookingDetails.group_promotion.deposit_percent,
                    })}
                    classes={{ tooltip: classes.tooltip }}>
                    <OccurrenceMenu
                      occurrences={bookingDetails.occurrences}
                      handleOccurenceChange={handleOccurenceChange}
                      occurrenceId={widgetData.occurrence_id}
                      label={t('fe_er_tour_package_selector:tour_start_date')}
                      getOccurrenceName={getOccurrenceName}
                    />
                  </Tooltip>
                )}

                {bookingError?.errors && (
                <FormErrors
                  errorMsgs={bookingError}
                  errorField="occurrence_id"
                />
                )}
              </>
            )}
        </Box>

        {(acceptableReservationsType !== 'with_motorcycle_reservations_only'
          || tourType.identifier !== TOUR_TYPES.GUIDED_OFFROAD) && (
          <Box my={2}>
            <Typography500 variant="h5">
              {t('fe_er_tour_package_selector:bike_selection')}
            </Typography500>

            <Box my={2}>
              <Grid container spacing={2}>
                {(acceptableReservationsType
                  === 'with_motorcycle_reservations_only'
                  || acceptableReservationsType === 'any_reservations') && (
                  <Grid
                    item
                    xs={12}
                    md={
                      acceptableReservationsType === 'any_reservations' ? 6 : 12
                    }>
                    <RadioButtonCardOld
                      title={t('fe_er_tour_package_selector:include_bike')}
                      active={includeBike}
                      onClick={() => handleIncludeBikeChange(true)}
                      size="small"
                      radioBtnPostion="left"
                    />
                  </Grid>
                )}

                {(acceptableReservationsType
                  === 'without_motorcycle_reservations_only'
                  || acceptableReservationsType === 'any_reservations') && (
                  <Grid
                    item
                    xs={12}
                    md={
                      acceptableReservationsType === 'any_reservations' ? 6 : 12
                    }
                    className={classes.bringOwnContainer}>
                    <RadioButtonCardOld
                      title={t('fe_er_tour_package_selector:bring_my_own')}
                      active={!includeBike}
                      onClick={() => handleIncludeBikeChange(false)}
                      size="small"
                      radioBtnPostion="left"
                    />
                    <div className={classes.newLabel}>
                      {t('fe_er_tour_package_selector:new')}
                    </div>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        )}

        <Box my={2} id="select_tour_package">
          <Typography500 variant="h5">
            {t('fe_er_tour_package_selector:select_tour_package')}
          </Typography500>

          {bookingDetails?.packages && (
            <Box mt={2}>
              {bookingDetails.packages.map((pack, index) => (
                <PackageRadioButton
                  offRoadPackages={
                    bookingDetails.tour.type_identifier === 'guided_recurring'
                  }
                  packageName={
                    bookingDetails.tour.type_identifier === 'guided_recurring'
                      ? t('fe_er_tour_package_selector:seater', {
                        seater_number: pack.people_count,
                        count: pack.people_count === 2 ? 1 : 3,
                      })
                      : null
                  }
                  index={index}
                  id={pack.id}
                  key={pack.id}
                  adultsNumber={pack.people_count}
                  numberOfMoto={pack.motorcycles_count}
                  numberOfBed={pack.beds_count}
                  pricePerPerson={moneyFormat(
                    Dinero({
                      amount: pack.price_per_person.amount,
                      currency: pack.price_per_person.currency_code,
                    }),
                  )}
                  active={pack.id == widgetData.package_id}
                  onClick={handlePackageChange}
                  showPriceOnly={
                    tourType.identifier === TOUR_TYPES.GUIDED_OFFROAD
                  }
                />
              ))}
              {bookingError?.errors?.package_id?.length > 0 && (
                <Alert
                  className="TPSTimeSlotsError"
                  severity="error"
                  icon={<ErrorIcon className="TPSTimeSlotsErrorIcon" />}>
                  {bookingError.errors.package_id.join(', ')}
                </Alert>
              )}
            </Box>
          )}
        </Box>
        {bookingDetails?.recurring_tour_availabilities.length > 0 && (
          <Box my={2} id="time_slots">
            <Typography500 variant="h5" mb={1}>
              {t('fe_er_tour_package_selector:time_slots')}
            </Typography500>
            <Box className="TPSTimeSlotsWrapper">
              {bookingDetails.recurring_tour_availabilities.map((item) => (
                <Box
                  key={item}
                  className={`TPSTimeSlots ${
                    item.id === widgetData.availability_id ? 'active' : ''
                  }`}
                  onClick={() => handleSelectTimeSlots(item)}>
                  <Typography className="TPSTimeSlotsText" variant="p">
                    {item.time_part}
                  </Typography>
                </Box>
              ))}
            </Box>
            {bookingError?.errors?.availability?.length > 0 && (
              <Alert
                className="TPSTimeSlotsError"
                severity="error"
                icon={<ErrorIcon className="TPSTimeSlotsErrorIcon" />}>
                {bookingError.errors.availability.join(', ')}
              </Alert>
            )}
          </Box>
        )}

        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography500 variant="h6">
                {t('fe_er_tour_package_selector:number_tour_packages')}
              </Typography500>
            </Grid>
            <Grid item xs={5} align="right">
              <Counter
                count={widgetData.packages_count || 1}
                min={1}
                max={10}
                onIncrement={handleIncrement}
                onDecrement={handleDecrement}
                inactive={false}
              />
            </Grid>
          </Grid>

          {bookingError?.errors?.packages_count?.length > 0 && (
            <Alert
              className="TPSTimeSlotsError"
              severity="error"
              icon={<ErrorIcon className="TPSTimeSlotsErrorIcon" />}>
              {bookingError.errors.packages_count.join(', ')}
            </Alert>
          )}
        </Box>

        <Box my={2} display="flex" justifyContent="center">
          <BestPriceGuarantee />
        </Box>

        <Box my={2}>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            onClick={handleBookTour}
            color="primary">
            {t('fe_er_tour_package_selector:book_tour')}
          </Button>
        </Box>

        <Typography variant="caption" display="block" align="center">
          {t('fe_er_tour_package_selector:wont_charged_yet')}
        </Typography>
      </Box>
    </Root>
  );
};

export default TourPackageSelector;
